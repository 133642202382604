import { Suspense } from "react";
//Router
import { BrowserRouter } from "react-router-dom";
//Context
import { I18nContextProvider } from "../context/I18nProvider";

//Componentes
import Layout from "../layout/Layout";
import Loader from "../components/Loader";

//Rutas
import Router from "./Router";

const BASE_URL = process.env.REACT_APP_BASENAME_URL;

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter basename={BASE_URL}>
        <I18nContextProvider>
          <Layout>
            <Router />
          </Layout>
        </I18nContextProvider>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
