import React from 'react'
import PropTypes from 'prop-types'

const TowerPartnersSvg = (props) => {
    //Simple SVG que se export como componente y se le pasa atributo fill con el color deseado
    // Ejemplo : <TowerSolidSvg fill="red" />

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={'100%'} height={'100%'} viewBox="0 0 121.778 37.5" {...props}>
            <path
                style={{ fill: `${props.fill}` }}
                d="M57.488 6.894a8.532 8.532 0 108.532 8.532 8.541 8.541 0 00-8.532-8.532zm4.79 8.532a4.79 4.79 0 11-4.79-4.79 4.795 4.795 0 014.785 4.79zM110.239 13.876a8.534 8.534 0 00-16.837 2.782 8.4 8.4 0 00.627 2.184 8.533 8.533 0 0015.638 0l.08-.183h-4.367l-.039.041a4.787 4.787 0 01-6.991 0 4.84 4.84 0 01-1.1-1.93h13.028l.016-.112a8.633 8.633 0 00-.053-2.781zm-12.886-.107a4.79 4.79 0 018.988 0zM121.656 6.919a8.53 8.53 0 00-.563-.02 8.465 8.465 0 00-4.783 1.465V6.899h-3.746v17.054h3.746v-8.252c-.005-.091-.007-.183-.007-.277s0-.185.007-.277a4.793 4.793 0 014.783-4.511 4.731 4.731 0 01.54.034l.145.016V6.924zM41.861 10.893h4.91v-4h-4.91V.005a4 4 0 00-4 4v11.287a8.685 8.685 0 003.59 7.019 8.594 8.594 0 005.063 1.634h.261v-4h-.261a4.651 4.651 0 01-4.649-4.414zM85.526 17.912L81.642 6.891h-3.947l-3.905 11.2-3.908-11.2h-4.014l5.937 17.067h3.979l3.908-11.222 3.905 11.222h3.98l5.945-17.067h-4.024l-3.908 11.2zM0 6.851h6.851V0A6.851 6.851 0 000 6.851zM20.553 0a6.851 6.851 0 00-6.851 6.851h6.851zM22.769 13.702h4.635V6.851h-6.851l-6.851 6.851v6.851H6.851v-6.851h6.851V6.851H6.851L0 13.702v6.851a6.851 6.851 0 006.851 6.851h20.553v-6.851h-6.851v-6.851z"
            />
            <g>
                <path
                    style={{ fill: `${props.fill}` }}
                    d="M47.206 33.311a1.493 1.493 0 01-.637.609 2.367 2.367 0 01-1.123.235h-1v2.246h-.908v-5.537h1.911a2.411 2.411 0 011.079.219 1.521 1.521 0 01.661.593 1.626 1.626 0 01.219.836 1.671 1.671 0 01-.202.799zm-.987-.131a.86.86 0 00.255-.665q0-.908-1.028-.907h-1v1.808h1a1.106 1.106 0 00.777-.236zM56.472 35.27h-2.317l-.4 1.131h-.948l1.983-5.543h1.051l1.983 5.543h-.956zm-.255-.741l-.9-2.572-.908 2.572zM66.418 36.401l-1.274-2.214h-.693v2.214h-.909v-5.537h1.912a2.376 2.376 0 011.079.223 1.549 1.549 0 01.661.6 1.626 1.626 0 01.219.836 1.655 1.655 0 01-.315.983 1.593 1.593 0 01-.968.6L67.5 36.4zm-1.967-2.939h1a1.051 1.051 0 00.768-.255.915.915 0 00.259-.685.887.887 0 00-.254-.673 1.083 1.083 0 00-.773-.243h-1zM77.014 30.864v.741H75.54v4.794h-.908v-4.794h-1.481v-.741zM87.329 36.401h-.908L83.69 32.27v4.133h-.908V30.86h.908l2.731 4.125V30.86h.908zM94.289 31.599v1.624H96.2v.74h-1.911v1.7h2.15v.741h-3.058v-5.543h3.058v.741zM105.282 36.401l-1.274-2.214h-.693v2.214h-.908v-5.537h1.911a2.375 2.375 0 011.079.223 1.541 1.541 0 01.661.6 1.621 1.621 0 01.219.836 1.658 1.658 0 01-.314.983 1.6 1.6 0 01-.968.6l1.37 2.294zm-1.966-2.939h1a1.052 1.052 0 00.769-.255.918.918 0 00.259-.685.883.883 0 00-.255-.673 1.081 1.081 0 00-.773-.243h-1zM113.123 36.261a1.646 1.646 0 01-.7-.554 1.4 1.4 0 01-.255-.836h.972a.874.874 0 00.282.589 1 1 0 00.7.231 1.085 1.085 0 00.733-.227.738.738 0 00.263-.585.641.641 0 00-.163-.454 1.1 1.1 0 00-.406-.271 6.2 6.2 0 00-.673-.207 6.3 6.3 0 01-.88-.29 1.451 1.451 0 01-.577-.458 1.324 1.324 0 01-.239-.829 1.473 1.473 0 01.239-.836 1.524 1.524 0 01.669-.55 2.435 2.435 0 011-.191 2.074 2.074 0 011.318.4 1.483 1.483 0 01.57 1.1h-1a.725.725 0 00-.286-.518 1.059 1.059 0 00-.693-.215.985.985 0 00-.638.2.7.7 0 00-.247.573.582.582 0 00.155.418 1.1 1.1 0 00.394.259 6.19 6.19 0 00.653.207 6.781 6.781 0 01.9.3 1.5 1.5 0 01.59.466 1.342 1.342 0 01.243.841 1.509 1.509 0 01-.227.8 1.638 1.638 0 01-.661.6 2.186 2.186 0 01-1.024.227 2.477 2.477 0 01-1.012-.19z"
                />
            </g>
        </svg>
    )
}
TowerPartnersSvg.propTypes = {
    fill: PropTypes.string.isRequired,
}

export default TowerPartnersSvg
