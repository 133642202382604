import React, { useContext } from "react";

import useWindowWidth from "../hooks/useWindowWidth";
import { I18nContext } from "../context/I18nProvider";

//Comps
import Footer from "./Footer/Footer";
import HeaderMobile from "./HeaderMobile/HeaderMobile";
import HeaderAside from "./HeaderAside/HeaderAside";
import Whatsapp from "../components/whatsapp/Whatsapp";

const Layout = ({ children }) => {
  const width = useWindowWidth();
  const { language } = useContext(I18nContext);

  return (
    <div className="prehome-tower-travel">

      {/* Este div maneja un row en desktop para el header y el main */}
      <div className="header-page-wrapper">
        {width < 1200 && <HeaderMobile language={language} />}
        <HeaderAside language={language} />
        {children}
      </div>

      <Whatsapp />

      <Footer language={language} />

    </div>
  );
};

export default Layout;
