import React, { createContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
//Hooks
import usePrevious from '../hooks/usePrevious'

export const I18nContext = createContext({})

export function I18nContextProvider({ children }) {
    const { i18n, t } = useTranslation() //instance of i18next

    const location = useLocation()
    const history = useHistory()

    //Este estado va a guardar el idioma si existe en el localStorage sino por default va a ser el de la instancia de i18n
    const [language, setLanguage] = useState(localStorage.getItem('i18nextLng') || i18n.language)
    const [actualComponent, setActualComponent] = useState(null)
    const [param, setParam] = useState(null) //Para paginas que tengan parametro, subir el paramentro al contexto
    const [ip, setIp] = useState('AR')//Para saber si el usuario accede de argentina u otro pais
    const previousLanguage = usePrevious(language)
    const previousComponent = usePrevious(actualComponent)

    //Solo actualiza el language del contexto
    useEffect(() => {
        setLanguage(i18n.language)
    }, [i18n.language])

    //Este path cambia por si solo cuando se cambia de lenguaje y/o de "pagina"
    let path = t(`routes.${actualComponent}`)

    useEffect(() => {

        if (path === 'routes.null') {
            return
        }
        /*
        A este punto ya sabemos que cambio o el lenguaje o el actualComponent 
        */
        const { pathname, search } = location

        //Si el lenguaje cambio
        if (language !== previousLanguage) {
            // CONTIENE SEARCH Y PARAMS
            if (search && param) {
                history.replace(`/${language}${path}/${param}${search}`)
                setParam(null)
                return
            }
            // CONTIENE SOLO PARAMS
            if (!search && param) {
                history.replace(`/${language}${path}/${param}`)
                setParam(null)
                return
            }
            // CONTIENE SOLO SEARCH
            if (search && !param) {
                history.replace(`/${language}${path}${search}`)
                return
            }
            //NO CONTIENE SEARCH NI PARAMS
            history.replace(`/${language}${path}`)
            return
        }
        // Si no cambio el lenguaje y solo cambio el actualComponent (cambio de pagina)
        if (actualComponent !== previousComponent) {
            /*
               Si solo hubo un cambio dde pagina y el pathname no contiene
            */
            const splitPath = pathname.split('/')
            const key = localStorage.getItem('i18nextLng')
            //SI EL PATHNAME NO CONTIENE EL LENGUAJE ACTUAL QUIERE DECIR QUE SE CAMBIO DE LENGUAJE EN OTRA PAGINA
            if (!splitPath.includes(key)) {
                // CONTIENE SEARCH Y PARAMS
                if (search && param) {
                    history.replace(`/${language}${path}/${param}${search}`)
                    setParam(null)
                    return
                }
                // CONTIENE SOLO PARAMS
                if (!search && param) {
                    history.replace(`/${language}${path}/${param}`)
                    setParam(null)
                    return
                }
                // CONTIENE SOLO SEARCH
                if (search && !param) {
                    history.replace(`/${language}${path}${search}`)
                    return
                }
                //NO CONTIENE SEARCH NI PARAMS
                history.replace(`/${language}${path}`)
                return
            }
        }
    }, [language, actualComponent]) //Cambio de pagina o lenguaje

    //Para saber desde donde accede el usuario, util para el orden de las columnas de prehome y los contactos de whatasapp
    useEffect(() => {
        fetch('https://ipapi.co/json')
          .then(response => {
            if(response.ok) {
              return response.json()
            }
          })
          .then(data => setIp(data.country))
          .catch((error) => console.error(error.message))
    
    }, [])

    return (
        <I18nContext.Provider value={{ setLanguage, language, actualComponent, setActualComponent, param, setParam, ip }}>
            {children}
        </I18nContext.Provider>
    )
}
