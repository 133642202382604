import { useState, useLayoutEffect } from 'react'

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const updateWidth = () => {
        setWindowWidth(window.innerWidth)
    }
    useLayoutEffect(() => {
        window.addEventListener('resize', updateWidth )
        return () => {
            window.removeEventListener('resize', updateWidth )
        };
    }, [])

    return windowWidth;
}

export default useWindowWidth
