/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Translate from "../i18n/Translate";

//Hooks
import useScrollTop from "../hooks/useScrollTop";
import { I18nContext } from "../context/I18nProvider";

//Components
import ContactForm from "../components/ContactForm";
import Socials from "../components/Socials";
import colors from "../assets/sass/variables/colors.module.scss";

const Contact = () => {
  const { setActualComponent } = useContext(I18nContext);
  const { colorClaro, colorSecundario } = colors;

  useScrollTop();

  useEffect(() => {
    setActualComponent("contact");
  }, []);

  return (
    <main className="prehome-contact page">
      <div className="prehome-contact__form">
        <h1 className="title">
          <Translate langkey="contact.title" />
        </h1>
        <h2>
          <Translate langkey="contact.subtitle" />
        </h2>
        <p>
          <Translate langkey="contact.copy" />
        </p>

        <ContactForm />
      </div>

      <div className="prehome-contact__info">
        <div className="group-one">
          <div className="info-group">
            <h3>
              <Translate langkey="contact.infogroup1.title" />
            </h3>
            <p>
              <Translate langkey="contact.infogroup1.copy" />
            </p>
          </div>
          <div className="info-group">
            <h3>
              <Translate langkey="contact.infogroup2.title" />
            </h3>
            <p>
              <Translate langkey="contact.infogroup2.copy" />
            </p>
          </div>
          <div className="info-group">
            <h3>
              <Translate langkey="contact.infogroup3.title" />
            </h3>
            <p>
              <Translate langkey="contact.infogroup3.copy" />
            </p>
          </div>

          <div className="socials-wrapper">
            <Socials fill={colorClaro} background={colorSecundario} />
          </div>
        </div>

        <div className="group-two">
          <div className="sedes">
            <h3>
              <Translate langkey="contact.sedes.title" />
            </h3>
            <div className="sede-group">
              <h4>
                <Translate langkey="contact.sedes.sedegroup1.title" />
              </h4>
              <address>
                <Translate langkey="contact.sedes.sedegroup1.address" />
              </address>
            </div>
            <div className="sede-group">
              <h4>
                <Translate langkey="contact.sedes.sedegroup2.title" />
              </h4>
              <address>
                <Translate langkey="contact.sedes.sedegroup2.address" />
              </address>
            </div>
            <div className="sede-group">
              <h4>
                <Translate langkey="contact.sedes.sedegroup3.title" />
              </h4>
              <address>
                <Translate langkey="contact.sedes.sedegroup3.address" />
              </address>
            </div>
            <div className="sede-group">
              <h4>
                <Translate langkey="contact.sedes.sedegroup4.title" />
              </h4>
              <address>
                <Translate langkey="contact.sedes.sedegroup4.address" />
              </address>
            </div>
            <div className="sede-group">
              <h4>
                <Translate langkey="contact.sedes.sedegroup5.title" />
              </h4>
              <address>
                <Translate langkey="contact.sedes.sedegroup5.address" />
              </address>
            </div>
            <div className="sede-group">
              <h4>
                <Translate langkey="contact.sedes.sedegroup6.title" />
              </h4>
              <address>
                <Translate langkey="contact.sedes.sedegroup6.address" />
              </address>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Contact;
