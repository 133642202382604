
import React, { useContext, useEffect, useState } from 'react'

//Language
import { I18nContext } from '../context/I18nProvider'
import { useTranslation } from 'react-i18next'
//Images
import bandera_es from "../assets/images/bandera-espanol-tower.svg";
import bandera_en from "../assets/images/bandera-ingles-tower.svg";
import bandera_pt from "../assets/images/bandera-portugues-tower.svg";

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const { language } = useContext(I18nContext);
  const [buttonFocus, setButtonFocus] = useState("");

  //El idioma lo cambiamos con la instancia de i18n para no correr 2 veces el contexto
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
  }

  useEffect(() => {
    setButtonFocus(language);
  }, [language]);

  return (
    <div className="language-selector">
      <button
        className={`btn-lang ${buttonFocus === "en" ? "active" : ""}`}
        aria-label="Change to english language"
        onClick={() => changeLanguage("en")}
        disabled={language === "en"}
      >
        <img src={bandera_en} alt={`${t("alts.flags.en")}`} />
      </button>
      <button
        className={`btn-lang ${buttonFocus === "es" ? "active" : ""}`}
        aria-label="Cambiar idioma a español"
        onClick={() => changeLanguage("es")}
        disabled={language === "es"}
      >
        <img src={bandera_es} alt={`${t("alts.flags.es")}`} />
      </button>
      <button
        className={`btn-lang ${buttonFocus === "pt" ? "active" : ""}`}
        aria-label="Cambiar idioma a portugues"
        onClick={() => changeLanguage("pt")}
        disabled={language === "pt"}
      >
        <img src={bandera_pt} alt={`${t("alts.flags.pt")}`} />
      </button>
    </div>
  );
};

export default LanguageSelector;
