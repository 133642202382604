import React from 'react'

const LinkedinSvg = ({fill}) => {
    return (
      <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20 20h-4v-6.999c0-1.92-.847-2.991-2.366-2.991-1.653 0-2.634 1.116-2.634 2.991V20H7V7h4v1.462s1.255-2.202 4.083-2.202C17.912 6.26 20 7.986 20 11.558V20zM2.442 4.921A2.451 2.451 0 010 2.46 2.451 2.451 0 012.442 0a2.451 2.451 0 012.441 2.46 2.45 2.45 0 01-2.441 2.461zM0 20h5V7H0v13z"
          fill={fill}
          fillRule="evenodd"
        />
      </svg>
    )
}

export default LinkedinSvg
