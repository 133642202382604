import React, { useState } from 'react'
import Translate from '../i18n/Translate';
//Intersection
import { useInView  } from 'react-intersection-observer';
//Hooks
import useWindowWidth from '../hooks/useWindowWidth';
//SVG
import TowerPartnersSvg from './svg/TowerPartnersSvg'
import TowerIncomingSvg from './svg/TowerIncomingSvg'
import TowerCorporateSvg from './svg/TowerCorporateSvg'
import TowerSportsSvg from './svg/TowerSportsSvg'


const OurProductsCard = ({ link, hoverColor, category }) => {
    
    const { ref, inView }  = useInView({
        threshold: 0.9,
    })
    const windowWidth = useWindowWidth();

    //Para crear un efecto de hover automatico en la Card en medidas menor o igual a 1024
    const inViewOnMobileOnly = windowWidth <= 1024 && inView;

    //Para determinar en distintos elementos cuando se hizo hover sobre el elemento
    const [hovered, setHovered] = useState(false)


    return (
        <div 
            className={`our-products-card ${hovered || inViewOnMobileOnly ? hoverColor : ''}`} 
            onMouseOver={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            ref={ref}
        >
            <div className="our-products-card__icon">
                {
                    category === 'incoming'
                    &&
                    <TowerIncomingSvg fill={`${hovered || inViewOnMobileOnly ? '#fff' : "#8E8E8E" }`}  />
                }
                {
                    category === 'corporate'
                    &&
                    <TowerCorporateSvg fill={`${hovered || inViewOnMobileOnly ? '#fff' : "#8E8E8E" }`}  />
                }
                {
                    category === 'sports'
                    &&
                    <TowerSportsSvg fill={`${hovered || inViewOnMobileOnly ? '#fff' : "#8E8E8E" }`}  />
                }
                {
                    category === 'partners'
                    &&
                    <TowerPartnersSvg fill={`${hovered || inViewOnMobileOnly ? '#212530' : "#8E8E8E" }`}  />
                }
            </div>
            

            <p className={`our-products-card__text ${hovered || inViewOnMobileOnly ? 'hovered' : ''}`}>
                <Translate langkey={`about.products.${category}`} />
            </p>
            <a href={link} target="_blank" rel="noreferrer" className={`our-products-card__link ${hovered || inViewOnMobileOnly ? 'hovered' : ''}`} aria-label="Ir a sitio tal...">

                <Translate langkey="about.products.seemore" />
                <div className="arrow-container">
                        <div className="arrow-line"/>
                </div>
            </a>
        </div>
    )
}

export default OurProductsCard
