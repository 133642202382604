import { useState, useContext } from 'react';
import api from '../../api/index'

//React Modal
import ReactModal from 'react-modal';

//React Icons
import { HiX } from 'react-icons/hi'

//I18N
import { useTranslation } from 'react-i18next'
import Translate from '../../i18n/Translate';
import { I18nContext } from '../../context/I18nProvider'

//Hooks
import useWindowWidth from '../../hooks/useWindowWidth';

//Components
import WhatsappAccordion from './WhatsappAccordion'
import WhatsappInfo from './WhatsappInfo';
import WhatsappLoader from './WhatsappLoader';
import WhatsappButton from './WhatsappButton';

const Whatsapp = () => {

    const { t } = useTranslation();

    const { language, ip } = useContext(I18nContext)

    const windowWidth = useWindowWidth()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const [contacts, setContacts] = useState([])

    const [initialContacts, setInitialContacts] = useState([])

    const [input, setInput] = useState("")

    const [usingInitialContacts, setUsingInitialContacts] = useState(true)



    //Modal
    ReactModal.setAppElement('#root')

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleClick = async () => {

        setModalIsOpen(!modalIsOpen)

        // Espermos la respuesta del servidor
        if (!initialContacts.length ) {
            setLoading(true)

            try {
                const apiData = await api.whatsapp.get({ language })
                //TODO - Segun el ip mostrar primero Partners o Incoming
                // Esto solo es para la prehome
                // ip === 'AR'  Partners primero 
                // ip !== 'AR'  Incoming primero

                let sectionsSorted = apiData.result.sort((a, b) => {
                    
                    if (ip === 'AR') {
                        if (a.title === 'partners') {
                            return -1
                        }
                        if (b.title === 'partners') {
                            return 1
                        }
                    } else {
                        if (a.title === 'incoming') {
                            return -1
                        }
                        if (b.title === 'incoming') {
                            return 1
                        }
                    }
                })

                setContacts( sectionsSorted )
                setInitialContacts( sectionsSorted )

            } catch (error) {
                console.error(error)
                setError(true)
            } finally {
                setLoading(false)
            }

        } else {
            setContacts(initialContacts)
        }
    }

    const handleClose = () => {
        setModalIsOpen(false)
        setInput("")
        setUsingInitialContacts(true)
    }

    //Setea el valor del input del buscador
    const handleChange = (e) => {

        setInput(e.target.value)
        if (!e.target.value) {
            setContacts(initialContacts)
            setUsingInitialContacts(true)
        }
    }

    //borrar valores en input
    const handleDelete = (e) => {
        e.preventDefault()
        setInput("")
        setContacts(initialContacts)
        setUsingInitialContacts(true)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        handleSearch(input)
    }

    const handleSearch = (searchValue) => {

        if (!searchValue) {

            setContacts(initialContacts)
            setUsingInitialContacts(true)
        } else {

            let foundedContacts = [];

            initialContacts.forEach(section => {

                let sectionContacts = section.contacts.filter(contact =>  contact.nameLastName.toLowerCase().includes(searchValue.toLowerCase()))

                if (sectionContacts.length) {
                    foundedContacts.push({
                        ...section,
                        contacts: sectionContacts
                    })
                }

            })

            setContacts(foundedContacts)
            setUsingInitialContacts(false)
        }
    }



    return (
        <div className="whatsapp">

            <WhatsappButton onClick={handleClick} modalIsOpen={modalIsOpen} x={windowWidth >= 1200 ? "left" : "right"} y="bottom" />

            {modalIsOpen && <ReactModal
                isOpen={modalIsOpen}
                parentSelector={() => document.querySelector('.prehome-tower-travel')}
                ariaHideApp={true}
                onRequestClose={handleClose}
                shouldCloseOnEsc={true}
                preventScroll={true}
                className="whatsapp__modal"
            >
                <button onClick={handleClose} className='close-btn'>
                    <HiX size="1.5em" />
                </button>
                <div className='whatsapp__modal__text'>
                    <h1 className='title'>
                        <Translate langkey="whatsapp.title" />
                    </h1>
                    <p className='copy'>
                        <Translate langkey="whatsapp.copy" />
                    </p>
                </div>
                <div className='white-bg-container'>
                
                    <form className='whatsapp__modal__search' onSubmit={handleSubmit}>
                        <button type="submit" className="search-btn" aria-label={t('faqs.searchBar.ariaLabelBtn')}>
                        </button>
                        <input onChange={handleChange} type="search" placeholder={`${t('whatsapp.search')}`} 
                                value={input} 
                                className='search-input' 
                        />
                        {input && (
                            <button onClick={handleDelete} className='delete-btn'>
                                <HiX size="1.5em" />
                            </button>
                        )}
                    </form>
                    
                    <div className='whatsapp__modal__info'>

                        {loading && <WhatsappLoader />}
                        {error && <Translate langkey='whatsapp.error'/>}
                        {!usingInitialContacts && contacts.length === 0 && <Translate langkey='whatsapp.not_found' />}
                        {
                            contacts.map(({title, contacts}) => {

                                return (
                                    <div key={title} className={title}>
                                        <WhatsappAccordion
                                            title={title === 'administration' ? t('whatsapp.administration') : title}
                                            defaultOpen={usingInitialContacts ? false : true}
                                        >
                                            {contacts.map((info, i) => {

                                                return (
                                                    <WhatsappInfo data={info} className={title} key={`${title}-${i}`} />
                                                )
                                            })}
                                        </WhatsappAccordion>
                                    </div>
                                ) 
                            })
                        }
                    </div>
                </div>
            </ReactModal>
            }
        </div>
    )
}

export default Whatsapp