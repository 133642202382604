const TOWER_API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const TOWER_API_DEPARTMENT = process.env.REACT_APP_API_DEPARTMENT
const TOWER_API_SUBSCRIPTION = process.env.REACT_APP_API_SUBSCRIPTION
const TOWER_API_CONTACT = process.env.REACT_APP_API_CONTACT
const TOWER_API_USER = process.env.REACT_APP_API_USER

async function callApi(origin, endpoint, options = {}) {
    options.headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...options.headers,
    }

    const url = origin + endpoint
    const response = await fetch(url, options)

    if(!response.ok) {
		const error = new Error(response.statusText);
		error.response = response;

		throw error;
    } else {
        const data = await response.json()

        return data
    }
}


const api = {
  contact: {
      default({ name, email, issue, message, language }) {

          return callApi(`${TOWER_API_BASE_URL}/${TOWER_API_CONTACT}`, '', {
              method: 'POST',
              headers: {
                  'Accept-Language': language,
              },
              body: JSON.stringify({
                  nameLastname: name,
                  email,
                  subject: issue,
                  comment: message,
                  department: TOWER_API_DEPARTMENT,
              }),
          })
      },
      subscription({ email, language }) {

        return callApi(`${TOWER_API_BASE_URL}/${TOWER_API_SUBSCRIPTION}`, '', {
            method: 'POST',
            headers: {
                'Accept-Language': language,
            },
            body: JSON.stringify({
                email,
                department: TOWER_API_DEPARTMENT,
            }),
        })
      },
  },
  whatsapp: {
        get({ language }) {
            return callApi(`${TOWER_API_BASE_URL}/${TOWER_API_USER}`, `/whatsapp/${TOWER_API_DEPARTMENT}`, {
                method: 'GET',
                headers: {
                    'Accept-Language': language,
                },
            })
        },
    },
}

export default api;