const Burger = ({ state, setter }) => {
  return (
    <button
      className={`hamburger ${state ? "open" : "closed"}`}
      onClick={() => setter(!state)}
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
};

export default Burger;
