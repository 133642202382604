import React from "react";

import Routes from "./Routes";

const Router = () => {
  const supportedLanguages = ["en", "pt", "es"];

  return <Routes supportedLanguages={supportedLanguages} />;
};

export default Router;
