
import { useEffect, useContext } from "react";
//Language
import Translate from "../i18n/Translate";
import { useTranslation } from "react-i18next";
//Hooks
import useScrollTop from "../hooks/useScrollTop";
import useWindowWidth from "../hooks/useWindowWidth";
import { I18nContext } from "../context/I18nProvider";
//Components
import TowerPartnersSvg from "../components/svg/TowerPartnersSvg";
import TowerIncomingSvg from "../components/svg/TowerIncomingSvg";
import TowerCorporateSvg from "../components/svg/TowerCorporateSvg";
import TowerSportsSvg from "../components/svg/TowerSportsSvg";
import CircleArrowSvg from "../components/svg/CircleArrowSvg";
//Images
import partners_mb from "../assets/images/Home-foto-header-partners-Tower-viajes-mobile.jpg";
import partners_mb_2x from "../assets/images/Home-foto-header-partners-Tower-viajes-mobile@2x.jpg";
import partners_mb_3x from "../assets/images/Home-foto-header-partners-Tower-viajes-mobile@3x.jpg";
import partners_dt from "../assets/images/Home-foto-header-partners-Tower-viajes-desk.jpg";
import partners_dt_2x from "../assets/images/Home-foto-header-partners-Tower-viajes-desk@2x.jpg";
import incoming_mb from "../assets/images/Home-foto-header-incoming-Tower-viajes-mobile.jpg";
import incoming_mb_2x from "../assets/images/Home-foto-header-incoming-Tower-viajes-mobile@2x.jpg";
import incoming_mb_3x from "../assets/images/Home-foto-header-incoming-Tower-viajes-mobile@3x.jpg";
import incoming_dt from "../assets/images/Home-foto-header-incoming-Tower-viajes-desk.jpg";
import incoming_dt_2x from "../assets/images/Home-foto-header-incoming-Tower-viajes-desk@2x.jpg";
import corporate_mb from "../assets/images/Home-foto-header-corporate-Tower-viajes-mobile.jpg";
import corporate_mb_2x from "../assets/images/Home-foto-header-corporate-Tower-viajes-mobile@2x.jpg";
import corporate_mb_3x from "../assets/images/Home-foto-header-corporate-Tower-viajes-mobile@3x.jpg";
import corporate_dt from "../assets/images/Home-foto-header-corporate-Tower-viajes-desk.jpg";
import corporate_dt_2x from "../assets/images/Home-foto-header-corporate-Tower-viajes-desk@2x.jpg";
import sports_mb from "../assets/images/Home-foto-header-sports-Tower-viajes-mobile.jpg";
import sports_mb_2x from "../assets/images/Home-foto-header-sports-Tower-viajes-mobile@2x.jpg";
import sports_mb_3x from "../assets/images/Home-foto-header-sports-Tower-viajes-mobile@3x.jpg";
import sports_dt from "../assets/images/Home-foto-header-sports-Tower-viajes-desk.jpg";
import sports_dt_2x from "../assets/images/Home-foto-header-sports-Tower-viajes-desk@2x.jpg";

const DOMAIN = process.env.REACT_APP_DOMAIN;



const Home = () => {
  const { setActualComponent, ip } = useContext(I18nContext);

  useScrollTop();

  const { t } = useTranslation();

  const windowWidth = useWindowWidth();

  useEffect(() => {
    setActualComponent("home");
  }, []);


  return (
    <main className="prehome-home page">
      {
        windowWidth < 1200 && (
          <section className="prehome-home__hero">
            <div className="titles">
              <h1>
                <Translate langkey="header.title" />
              </h1>
              <h2>
                <Translate langkey="header.subtitle" />
              </h2>
            </div>
          </section>
        )
      }


      {ip === 'AR' ? (
            <>
            <div className="tower-partners prehome-home__column" tabIndex="0">
              <div className="color-tag v-cuatro">
                <TowerPartnersSvg fill="#212530" />
              </div>
              <div className="bg-img">
                <picture>
                  <source
                    media="(max-width: 1199px )"
                    srcSet={`${partners_mb} 1x, ${partners_mb_2x} 2x, ${partners_mb_3x} 3x `}
                  />
                  <source
                    media="(min-width: 1200px )"
                    srcSet={`${partners_dt} 1x, ${partners_dt_2x} 2x`}
                  />
                  <img src={partners_mb} alt={`${t("home.partners.imgAlt")}`} />
                </picture>
              </div>
              <div className="inner-content">
                <h3>
                  <Translate langkey="home.partners.title" />
                </h3>
                <p>
                  <Translate langkey="home.partners.description" />
                </p>

                <a
                  href={`${DOMAIN}/partners/`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="content-link"
                  aria-label="Ir a sitio tal..."
                >
                  <CircleArrowSvg hoverStrokeColor="#fcde55" hoverStrokeWidth="2.5" />
                </a>
              </div>
            </div>

            <div className="tower-incoming prehome-home__column" tabIndex="0">
              <div className="color-tag v-uno">
                <TowerIncomingSvg fill="#fff" />
              </div>
              <div className="bg-img">
                <picture>
                  <source
                    media="(max-width: 1199px )"
                    srcSet={`${incoming_mb} 1x, ${incoming_mb_2x} 2x, ${incoming_mb_3x} 3x `}
                  />
                  <source
                    media="(min-width: 1200px )"
                    srcSet={`${incoming_dt} 1x, ${incoming_dt_2x} 2x`}
                  />
                  <img src={incoming_mb} alt={`${t("home.incoming.imgAlt")}`} />
                </picture>
              </div>
              <div className="inner-content">
                <h3>
                  <Translate langkey="home.incoming.title" />
                </h3>
                <p>
                  <Translate langkey="home.incoming.description" />
                </p>
                <a
                  href={`${DOMAIN}/incoming/`}
                  target="_blank"
                  rel="noreferrer"
                  className="content-link"
                  aria-label="Ir a sitio tal..."
                >
                  <CircleArrowSvg hoverStrokeColor="#5ecfea" hoverStrokeWidth="2.5" />
                </a>
              </div>
            </div>
            </>
          ) : (
            <>
              <div className="tower-incoming prehome-home__column" tabIndex="0">
              <div className="color-tag v-uno">
                <TowerIncomingSvg fill="#fff" />
              </div>
              <div className="bg-img">
                <picture>
                  <source
                    media="(max-width: 1199px )"
                    srcSet={`${incoming_mb} 1x, ${incoming_mb_2x} 2x, ${incoming_mb_3x} 3x `}
                  />
                  <source
                    media="(min-width: 1200px )"
                    srcSet={`${incoming_dt} 1x, ${incoming_dt_2x} 2x`}
                  />
                  <img src={incoming_mb} alt={`${t("home.incoming.imgAlt")}`} />
                </picture>
              </div>
              <div className="inner-content">
                <h3>
                  <Translate langkey="home.incoming.title" />
                </h3>
                <p>
                  <Translate langkey="home.incoming.description" />
                </p>
                <a
                  href={`${DOMAIN}/incoming/`}
                  target="_blank"
                  rel="noreferrer"
                  className="content-link"
                  aria-label="Ir a sitio tal..."
                >
                  <CircleArrowSvg hoverStrokeColor="#5ecfea" hoverStrokeWidth="2.5" />
                </a>
              </div>
            </div>
            <div className="tower-partners prehome-home__column" tabIndex="0">
              <div className="color-tag v-cuatro">
                <TowerPartnersSvg fill="#212530" />
              </div>
              <div className="bg-img">
                <picture>
                  <source
                    media="(max-width: 1199px )"
                    srcSet={`${partners_mb} 1x, ${partners_mb_2x} 2x, ${partners_mb_3x} 3x `}
                  />
                  <source
                    media="(min-width: 1200px )"
                    srcSet={`${partners_dt} 1x, ${partners_dt_2x} 2x`}
                  />
                  <img src={partners_mb} alt={`${t("home.partners.imgAlt")}`} />
                </picture>
              </div>
              <div className="inner-content">
                <h3>
                  <Translate langkey="home.partners.title" />
                </h3>
                <p>
                  <Translate langkey="home.partners.description" />
                </p>

                <a
                  href={`${DOMAIN}/partners/`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="content-link"
                  aria-label="Ir a sitio tal..."
                >
                  <CircleArrowSvg hoverStrokeColor="#fcde55" hoverStrokeWidth="2.5" />
                </a>
              </div>
            </div>
            </>
          )
      }

      <div className="tower-corporate prehome-home__column" tabIndex="0">
        <div className="color-tag v-dos">
          <TowerCorporateSvg fill="#fff" />
        </div>
        <div className="bg-img">
          <picture>
            <source
              media="(max-width: 1199px )"
              srcSet={`${corporate_mb} 1x, ${corporate_mb_2x} 2x, ${corporate_mb_3x} 3x `}
            />
            <source
              media="(min-width: 1200px )"
              srcSet={`${corporate_dt} 1x, ${corporate_dt_2x} 2x`}
            />
            <img src={corporate_mb} alt={`${t("home.corporate.imgAlt")}`} />
          </picture>
        </div>
        <div className="inner-content">
          <h3>
            <Translate langkey="home.corporate.title" />
          </h3>
          <p>
            <Translate langkey="home.corporate.description" />
          </p>
          <a
            href={`${DOMAIN}/corporate/`}
            target="_blank"
            rel="noreferrer"
            className="content-link"
            aria-label="Ir a sitio tal..."
          >
            <CircleArrowSvg hoverStrokeColor="#3a72b6" hoverStrokeWidth="2.5" />
          </a>
        </div>
      </div>

      <div className="tower-sports prehome-home__column" tabIndex="0">
        <div className="color-tag v-tres">
          <TowerSportsSvg fill="#fff" />
        </div>
        <div className="bg-img">
          <picture>
            <source
              media="(max-width: 1199px )"
              srcSet={`${sports_mb} 1x, ${sports_mb_2x} 2x, ${sports_mb_3x} 3x `}
            />
            <source
              media="(min-width: 1200px )"
              srcSet={`${sports_dt} 1x, ${sports_dt_2x} 2x`}
            />
            <img src={sports_dt} alt={`${t("home.sports.imgAlt")}`} />
          </picture>
        </div>
        <div className="inner-content">
          <h3>
            <Translate langkey="home.sports.title" />
          </h3>
          <p>
            <Translate langkey="home.sports.description" />
          </p>
          <a
            href="https://www.towertravelsports.com.ar"
            target="_blank"
            rel="noreferrer"
            className="content-link"
            aria-label="Ir a sitio tal..."
          >
            <CircleArrowSvg hoverStrokeColor="#e82221" hoverStrokeWidth="2.5" />
          </a>
        </div>
      </div>
    </main>
  );
};

export default Home;
