import {  useEffect, useState } from 'react'

const AccordionForm = ({ children, title, defaultOpen = false}) => {

    const [panelOpen, setPanelOpen] = useState(defaultOpen)

    //Abrir y cerrar panel, si se esta abriendo tambien se hace el foco scrolleando al top con un margen.
    const togglePanel = () => {
        setPanelOpen(!panelOpen)
    }

    useEffect(() => {

        setPanelOpen(defaultOpen)
    }, [defaultOpen])

    return (
        <div className="whatsapp-accordion">
            <header className={`whatsapp-accordion__header`}>
                <div
                    className={`whatsapp-accordion__indicator ${panelOpen ? 'open' : 'closed'}`}
                    onClick={togglePanel}
                    role="button"
                >
                <h3 className="whatsapp-accordion__title">{title}</h3>
                </div>
            </header>
            <div className={`whatsapp-accordion__panel ${panelOpen ? 'open' : 'closed'}`}>{children}</div>
        </div>
    )
}

export default AccordionForm
