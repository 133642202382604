/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Translate from "../i18n/Translate";

//Hooks
import useScrollTop from "../hooks/useScrollTop";
import { I18nContext } from "../context/I18nProvider";

//Components
import WorkWithGallery from "../components/WorkWithGallery";
import PlacesGallery from "../components/PlacesGallery";
import ProductsGallery from "../components/ProductsGallery";

const About = () => {
  const { setActualComponent } = useContext(I18nContext);

  useScrollTop();

  useEffect(() => {
    setActualComponent("about");
  }, []);

  return (
    <main className="prehome-about page">
      <h1 className="prehome-about__title">
        <Translate langkey="about.title" />
      </h1>

      <article className="prehome-about__article">
        <h2>
          <Translate langkey="about.article.title" />
        </h2>
        <p>
          <Translate langkey="about.article.p1" />
        </p>
        <br />
        <p>
          <Translate langkey="about.article.p2" />
        </p>
        <br />
        <p>
          <Translate langkey="about.article.p3" />
        </p>
      </article>

      {/* PLACES GALLERY SLIDER */}
      <PlacesGallery />

      <div className="prehome-about__work-with">
        <h3>
          <Translate langkey="about.workwith.title" />
        </h3>
        {/* WORKWITH GALLERY SLIDER */}
        <WorkWithGallery />
      </div>

      <div className="prehome-about__our-products">
        <div className="prods-text-wrapper">
          <h3>
            <Translate langkey="about.products.title" />
          </h3>
          <p>
            <Translate langkey="about.products.description" />
          </p>
        </div>

        {/* PRODUCTS GALLERY SLIDER */}
        <ProductsGallery />
      </div>
    </main>
  );
};

export default About;
