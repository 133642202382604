import React, { useState, useEffect } from "react";
//Routing
import { NavLink, Link } from "react-router-dom";
//Language
import { useTranslation } from "react-i18next";
import Translate from "../../i18n/Translate";

//Components
import Burger from "./components/Burger";
import LanguageSelector from "../../components/LanguageSelector";
import Socials from "../../components/Socials";
//Images
import tower_logo from "../../assets/images/tower_logo.svg";
//SCSS VARIABLES
import colors from "../../assets/sass/variables/colors.module.scss";

const HeaderMobile = ({ language }) => {
  const { t } = useTranslation();
  const { colorSecundario } = colors;

  const [navState, setNavState] = useState(false);

  //Bloquear scroll del body si esta el menu abierto
  useEffect(() => {
    if (navState) {
      document.body.style.overflowY = "hidden";
      document.body.style.maxHeight = "100vh";
    } else {
      document.body.style.overflowY = "";
      document.body.style.maxHeight = "";
    }
  }, [navState]);

  return (
    <header className="header_prehome_mobile">
      <div className="header_prehome_mobile__top">
        <div className="logo">
          <Link to={`${t("routes.home")}`}>
            <img src={tower_logo} alt="tower-travel logo" />
          </Link>
        </div>

        <div className="burger">
          <Burger state={navState} setter={setNavState} />
        </div>
      </div>

      {/* Contenido desplegable del menu */}
      <div
        className={`header_prehome_mobile__options ${navState ? "open" : ""}`}
      >
        <nav className="navigation">
          <ul className="links-list">
            <li>
              <NavLink
                to={`/${language}${t("routes.home")}`}
                exact
                onClick={() => setNavState(false)}
              >
                <Translate langkey="header.homeLink" />
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/${language}${t("routes.about")}`}
                onClick={() => setNavState(false)}
              >
                <Translate langkey="header.nosotrosLink" />
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/${language}${t("routes.contact")}`}
                onClick={() => setNavState(false)}
              >
                <Translate langkey="header.contactoLink" />
              </NavLink>
            </li>
          </ul>
        </nav>
        <div className="socials-wrapper">
          <Socials fill={colorSecundario} background="transparent" />
        </div>

        <LanguageSelector />
      </div>
    </header>
  );
};

export default HeaderMobile;
