const WhatsappLoader = () => {

    return (
        <div className="whatsapp__modal__info whatsapp-loader">
        <div className="card-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    )
}

export default WhatsappLoader