import React from 'react'
import { useTranslation } from 'react-i18next'
//Comps
import ScrollSnap from './ScrollSnap'
//Images
import places_0 from '../assets/images/About-Tower-viajes-0.jpg'
import places_0_2x from '../assets/images/About-Tower-viajes-0@2x.jpg'
import places_0_3x from '../assets/images/About-Tower-viajes-0@3x.jpg'
import places_1 from '../assets/images/About-Tower-viajes-1.jpg'
import places_1_2x from '../assets/images/About-Tower-viajes-1@2x.jpg'
import places_1_3x from '../assets/images/About-Tower-viajes-1@3x.jpg'
import places_2 from '../assets/images/About-Tower-viajes-2.jpg'
import places_2_2x from '../assets/images/About-Tower-viajes-2@2x.jpg'
import places_2_3x from '../assets/images/About-Tower-viajes-2@3x.jpg'
import places_3 from '../assets/images/About-Tower-viajes-3.jpg'
import places_3_2x from '../assets/images/About-Tower-viajes-3@2x.jpg'
import places_3_3x from '../assets/images/About-Tower-viajes-3@3x.jpg'

const PlacesGallery = () => {
    const { t } = useTranslation()

    const paddingsScrollSnap = {
        left: {
            mobile: 2,
            tablet: 4, //768
            s: 4, //992
            m: 7, //1200
            l: 7, //1480
            xl: 9, //1780W
        },
    }

    return (
        <div className="prehome-about__places-gallery">
            <ScrollSnap
                type="mandatory"
                columnGap="15"
                columnGapDt="30"
                columnGapDtLarge="40"
                paddings={paddingsScrollSnap}
            >
                <div className="place-img">
                    <img
                        draggable="false"
                        src={places_0}
                        srcSet={`${places_0} 1x,${places_0_2x} 2x, ${places_0_3x} 3x`}
                        alt={`${t('about.placesGallery.img1')}`}
                    />
                </div>
                <div className="place-img">
                    <img
                        draggable={false}
                        src={places_1}
                        srcSet={`${places_1} 1x,${places_1_2x} 2x, ${places_1_3x} 3x`}
                        alt={`${t('about.placesGallery.img2')}`}
                    />
                </div>
                <div className="place-img">
                    <img
                        draggable={false}
                        src={places_2}
                        srcSet={`${places_2} 1x,${places_2_2x} 2x, ${places_2_3x} 3x`}
                        alt={`${t('about.placesGallery.img3')}`}
                    />
                </div>
                <div className="place-img">
                    <img
                        draggable={false}
                        src={places_3}
                        srcSet={`${places_3} 1x,${places_3_2x} 2x, ${places_3_3x} 3x`}
                        alt={`${t('about.placesGallery.img4')}`}
                    />
                </div>
            </ScrollSnap>
        </div>
    )
}

export default PlacesGallery
