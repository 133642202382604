import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Home from "../pages/Home";
import About from "../pages/About";
import Contact from "../pages/Contact";
import NotFound from "../pages/NotFound";

const Routes = ({ supportedLanguages }) => {
  const getLanguage = localStorage.getItem("i18nextLng");
  const language =
    supportedLanguages.find((lang) => lang === getLanguage) || "es";

  return (
    <>
      <Switch>
        <Route
          exact
          path={["/", "/:lang/home", "/:lang/inicio", "/:lang/comeco"]}
          component={Home}
        />
        <Route
          path={["/:lang/contacto", "/:lang/contato", "/:lang/contact"]}
          component={Contact}
        />
        <Route
          path={["/:lang/about", "/:lang/nosotros", "/:lang/sobrenos"]}
          component={About}
        />
        {/* 
            Esto es por si se entra a towertravel.com.ar/algo/ 
            y ese 'algo' es [en, pt o es], entonces redirige a la home
            PERO sino es [en, pt o es], es que puede ser towertravel.com.ar/contacto 
            ahi entonces redirige a towertravel.com.ar/lang/contacto

            EXCEPTO QUE SEA 'prehome', en ese caso estamos en Staging y no redirige
        */}
        <Route
          exact
          path="/:lang/"
          render={(props) =>
            supportedLanguages.includes(props.match.params.lang)  ? (
              <Home {...props} />
            ) : (
              <Redirect to={`/${language}/${props.match.params.lang}`} />
            )
          }
        />


        <Route>
          <NotFound />
        </Route>
      </Switch>
    </>
  );
};

export default Routes;
