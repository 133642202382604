import { useState, useEffect, useContext } from 'react'
import api from '../api'
import { I18nContext } from "../context/I18nProvider";
import { useTranslation } from 'react-i18next';
import Translate from '../i18n/Translate.js';
import { useForm } from "react-hook-form";
//Svg
import MailWhite from "../assets/images/icons/envelope-white.svg";


const SubscribeForm = () => {
    const { t } = useTranslation()

    const { language } = useContext(I18nContext)
    //Substraer todos los caracteres luego de los dos primeros en caso de que el language sea mayor a 2 EJ: es-ES
    let lang = language.length > 2 ? language.substr(0, 2) : language;

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm()

    const [sendedForm, setSendedForm] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState({
        loading: false,
        error: null,
        response: null,
    })

    useEffect(() => {
        if (isSubmitting.response) {
            if (isSubmitting.response.status) {
                setSendedForm(isSubmitting.response.status)
                //Si la respuesta es TRUE reseteo el formulario
                isSubmitting.response.status && reset()
            } else {
                setSendedForm(isSubmitting.response.status)
            }
        }

        //Si existe un error atrapado por el catch reseteo form y aviso al usuario
        isSubmitting.error && reset()
    }, [isSubmitting, reset])

    // Al momento de hacer submit
    const onSubmit = async ({ email }) => {
        // creamos un estado de carga
        setIsSubmitting({ loading: true, error: null })

        // Esperamos la respuesta del servidor
        try {
            const apiData = await api.contact.subscription({ email, language: lang })
            setIsSubmitting({ loading: false, response: apiData })
            console.log(apiData)
        } catch (error) {
            console.error(error)
            setIsSubmitting({ loading: false, error: error })
        }
    }

    let emailRegistration = register('email', {
        required: `${t('form.requiredField')}`,
        pattern: {
            value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            message: `${t('form.wrongField')}`,
        },
    })

    return (
        <form className="subscribe-form" onSubmit={handleSubmit(onSubmit)}>
            <input
                {...emailRegistration}
                type="text"
                placeholder="Email"
                aria-invalid={errors.email ? 'true' : 'false'}
                onFocus={() => {
                    setIsSubmitting({ loading: false, error: null, response: null })
                    setSendedForm(false)
                }}
            />

            <button type="submit">
                <img src={MailWhite} alt="Mail Icon" />
            </button>
            {
                //Error de input
                errors.email && (
                    <span className="error-span">
                        <small>{errors.email.message}</small>
                    </span>
                )
            }
            {
                //Enviando...
                isSubmitting.loading && (
                    <span className="sended-span loading">
                        <Translate langkey="form.loadingMsg" />
                    </span>
                )
            }
            {
                //Formulario enviado
                sendedForm && (
                    <span className="sended-span">
                        <Translate langkey="form.sendedMsg" />
                    </span>
                )
            }
            {
                //Error atrapado por catch
                isSubmitting.error && (
                    <span className="error-span">
                        <small>
                            <Translate langkey="form.errorMsg" />
                        </small>
                    </span>
                )
            }
            {
                //Mismo email enviado
                isSubmitting?.response?.status === false && (
                    <span className="error-span">
                        <small>
                            <Translate langkey="form.repeatedEmail" />
                        </small>
                    </span>
                )
            }
        </form>
    )
}

export default SubscribeForm

