//React Icons
import { RiWhatsappFill } from "react-icons/ri";

const WhatsappButton = ({onClick, modalIsOpen, x="right", y="bottom"}) => {

    //Se posiciona el botón según los valores que se asignen a X (left/right) y a Y (top/bottom)
    const style = {
        left: `${x === 'left' ? '2rem' : 'unset'}`,
        right: `${x === 'right' ? '2rem' : 'unset'}`,
        top: `${y === 'top' ? '2rem' : 'unset'}`,
        bottom: `${y === 'bottom' ? '2rem' : 'unset'}`
    }

    return(
        <button onClick={onClick} className={`whatsapp__btn ${modalIsOpen ? 'hidden' : ''}`} style={style}>
            <RiWhatsappFill size="100%" />
            <span className="background-circle"></span>
        </button>
    )
}

export default WhatsappButton