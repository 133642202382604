//Routing
import { NavLink, Link } from 'react-router-dom'
//Language
import { useTranslation } from 'react-i18next'
import Translate from '../../i18n/Translate'

//Components
import LanguageSelector from '../../components/LanguageSelector'
//Images
import tower_logo from '../../assets/images/tower_logo.svg'

const HeaderAside = ({ language }) => {
    const { t } = useTranslation()

    return (
        <header className="header_prehome_HeaderAside">
            <div className="header_prehome_HeaderAside__logo">
                <Link to={`/${language}${t('routes.home')}`}>
                    <img src={tower_logo} alt="tower-travel logo" />
                </Link>
            </div>
            <div className="header_prehome_HeaderAside__title">
                <h1>
                    <Translate langkey="header.title" />
                </h1>
                <h2>
                    <Translate langkey="header.subtitle" />
                </h2>
            </div>

            <div className="header_prehome_HeaderAside__language-links-dt">
                <nav className="navigation-dt">
                    <ul className="links-list">
                        <li>
                            <NavLink to={`/${language}${t('routes.about')}`}>
                                <Translate langkey="header.nosotrosLink" />
                            </NavLink>
                        </li>
                        <span>|</span>
                        <li>
                            <NavLink to={`/${language}${t('routes.contact')}`}>
                                <Translate langkey="header.contactoLink" />
                            </NavLink>
                        </li>
                    </ul>
                </nav>

                <LanguageSelector />
            </div>
        </header>
    )
}

export default HeaderAside
