import React from 'react';

import Logo from '../assets/images/tower_logo.svg';

const Loader = () => {
  return (
    <div className="loader">
      <img src={Logo} alt="Logo" />
    </div>
  );
};

export default Loader;