import React, { useState } from 'react'

const CircleArrowSvg = ({  stroke="#fff", fill = 'transparent', fillHover = 'transparent', hoverStrokeColor, strokeWidth= 1.5, hoverStrokeWidth}) => {

    const [strokeColor, setStrokeColor] = useState(stroke)
    const [strkWidth, setStrokeWidth] = useState(strokeWidth)
    const [fillColor, setFillColor] = useState(fill)

    const style = {
        stroke: `${strokeColor}`,
        transition: '300ms all'
    }

    return (
        <svg
            onMouseEnter = {() => {
                setStrokeColor(hoverStrokeColor);
                setStrokeWidth(hoverStrokeWidth);
                setFillColor(fillHover)
            }}
            onMouseLeave = {() => {
                setStrokeColor(stroke)
                setStrokeWidth(strokeWidth);
                setFillColor(fill)
            }}
            xmlns="http://www.w3.org/2000/svg"
            width={37.585}
            height={37.585}
            >
            <g transform="translate(.75 .75)">
                <circle
                    cx={18.043}
                    cy={18.043}
                    r={18.043}
                    strokeMiterlimit={10}
                    fill={`${fillColor}`}
                    style={style}
                    strokeWidth={1.5}
                />
                <path
                    style={{ fill: 'none', strokeLinecap:'round',strokeLinejoin:'round',strokeWidth: `${strkWidth}`, ...style }}
                    d="M26.656 18.042H9.235M20.983 12.181l5.861 5.862-5.861 5.861"
                />
            </g>
        </svg>
    )
}

export default CircleArrowSvg
