import React, { useRef, useState, useEffect, useCallback } from 'react'
//HOOKS
import useWindowWidth from '../hooks/useWindowWidth';
import checkInViewport from '../hooks/checkInViewport';
import PropTypes from 'prop-types'


// columnGap le da margin-right a todos los items que coloquemos dentro del scrollSnap excepto al ultimo hasta 1200px
// columnGapDt le da margin-right a todos los items que coloquemos dentro del scrollSnap desde 1200px para arriba

const ScrollSnap = ({ 
    children, 
    type, 
    columnGap = 0, 
    columnGapDt = 0 , 
    columnGapDtLarge = 0 , 
    goPrev, 
    goNext, 
    setEndOfSlider,
    setStartOfSlider, 
    autoSlide=false,
    align='center',
    paddings
    }) => {


    const windowWidth = useWindowWidth();

    const columnGapFormatted = windowWidth < 1200 ?  Number(columnGap) : 
        windowWidth >= 1200 && windowWidth < 1920 ? Number(columnGapDt) : Number(columnGapDtLarge);


    const containerRef = useRef();
    const [ mouseDown, setMouseDown ] = useState(false);
    const [ mouseOver, setMouseOver ] = useState(false);
    const [pos, setPos] = useState({})

    const delay = typeof autoSlide === 'number' ? autoSlide : 2500;
    
    const handleMouseDown = (e) => {
        setMouseDown(true)
        setPos({
            // The current scroll 
            left: containerRef.current.scrollLeft,
            // Get the current mouse position
            x: e.clientX,
        });
    }
    const handleMouseUp = () => {
        setMouseDown(false)
    }
    const handleMouseMove = (e) => {
        if (mouseDown) {
            // How far the mouse has been moved
            const dx = e.clientX - pos.x;
            // Scroll the element
            containerRef.current.scrollLeft = pos.left - dx;
        }
    }

    const handleNext = useCallback(() => {
        const childWidth = containerRef.current.children[0].offsetWidth;
        //Ancho del contenedor padre a el scrollSnap dividido el ancho de un item redondeado nos da los items en vista
        const itemsInViewOfContainer = Math.floor(containerRef.current.parentNode.clientWidth / childWidth);
        
        containerRef.current.style.scrollBehavior = 'smooth';
        containerRef.current.style.scrollBehavior = 'smooth';
        //Scrolleamos
        containerRef.current.scrollLeft = containerRef.current.scrollLeft + (((childWidth * itemsInViewOfContainer) + (columnGapFormatted * itemsInViewOfContainer)) );

    },[columnGapFormatted])

    const handlePrev = useCallback(() => {
        const childWidth = containerRef.current.children[0].offsetWidth;
        //Ancho del contenedor padre a el scrollSnap dividido el ancho de un item redondeado nos da los items en vista
        const itemsInViewOfContainer = Math.floor(containerRef.current.parentNode.clientWidth / childWidth);
        
        containerRef.current.style.scrollBehavior = 'smooth';
        //Scrolleamos
        containerRef.current.scrollLeft = containerRef.current.scrollLeft - (((childWidth * itemsInViewOfContainer) - (columnGapFormatted * itemsInViewOfContainer)) );

    },[columnGapFormatted])

    const resetTransitionStyle = () => {
        if(containerRef.current) {
            containerRef.current.style.scrollBehavior = '';
        }
    }
    //AVANZA
    useEffect(() => {
        goNext && handleNext();
        !goNext && resetTransitionStyle();
    
    }, [goNext, handleNext])
    //RETROCEDE
    useEffect(() => {
        goPrev && handlePrev();
        !goPrev && resetTransitionStyle();
    
    }, [goPrev, handlePrev])
    //AUTOSLIDE QUE COMIENZA SI ESTA EN VISTA EL SLIDER
    useEffect(() => {
        const autoSlideInterval = setInterval(() => {

            const isInViewport = containerRef.current && checkInViewport( containerRef.current.getBoundingClientRect() );
            
            if (isInViewport){
                if (autoSlide && !mouseOver) {
                

                    if(sliderProgress() === 100) {
                        containerRef.current.style.scrollBehavior = 'smooth';
                        //Scrolleamos
                        containerRef.current.scrollLeft = 0;
    
                    } else {
    
                        handleNext();
                    }
                }
            }

        }, delay);
  
        return () => {
          if(autoSlide) {
            clearInterval(autoSlideInterval);
            resetTransitionStyle();
          } 
        };
    }, [ handleNext, autoSlide, mouseOver,delay ]);

    //DEVUELVE PORCENTAGE DE SCROLLEO
    const sliderProgress = () => {
        const scrolled = (containerRef.current.scrollLeft / (containerRef.current.scrollWidth - containerRef.current.clientWidth)) *  100;
        return scrolled  
    }
    //UPSTATE LIFTING, SUBE EL ESTADO AL COMPONENTE QUE LO USA AVISANDO QUE YA SE LLEGO AL FINAL (PARA OCULTAR FLECHAS Y DEMAS)
    const handleEndOfSlider = () => {

        if(setEndOfSlider) {
            const scrolled = sliderProgress();
        
            //Si se llego a mas de un 95% del contenedor scrolleado
            if(scrolled > 95) {
                setEndOfSlider(true)
            } else {
                setEndOfSlider(false)
            }

            if (scrolled == 0) {
                setStartOfSlider(true)
            } else {
                setStartOfSlider(false)
            }
        }
    }

    //Estilo en linea para el contenedor para aplicar el padding-left por el momento
    const containerStyle = (paddings) => {
        const paddingsToApply = [];

        if (paddings.right) {
        Object.keys(paddings.right).map((key) => {
            paddingsToApply.push(`pr_${key}-${paddings.right[key]}`);
        });
        }
        if (paddings.left) {

        Object.keys(paddings.left).map((key) => {
            paddingsToApply.push(`pl_${key}-${paddings.left[key]}`);
        });
        }
        return paddingsToApply.join(" ");
    };
  
    const paddingClasses = paddings && `${containerStyle(paddings)}`

    //Estilos para el contenedor en className
    const containerClass = `scroll-snap-container ${type} gap-${columnGapFormatted} align-${align} ${paddingClasses} `;

    return (
        <div 
            className={`${containerClass}` } 
            ref={ containerRef }
            onMouseDown = { (e) => handleMouseDown(e) }
            onMouseMove = { (e) => handleMouseMove(e) }
            onMouseUp = { handleMouseUp }
            onMouseLeave = { () => {
                handleMouseUp();
                setMouseOver(false);
            }  }
            onMouseOver = { () => setMouseOver(true)}
            onScroll={handleEndOfSlider}
        >
            {
                children
            }
        </div>
    )
}
//Props obligatorias
ScrollSnap.propTypes = {

    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,

    type: PropTypes.string.isRequired,

    columnGap: PropTypes.string.isRequired,
    columnGapDt: PropTypes.string.isRequired,

}

export default ScrollSnap;