import React from 'react'
//Comps
import ScrollSnap from './ScrollSnap'
//Images
import client_1 from '../assets/images/client_1.png'
import client_2 from '../assets/images/client_2.png'
import client_3 from '../assets/images/client_3.png'
import client_4 from '../assets/images/client_4.png'
import client_5 from '../assets/images/client_5.png'
import client_6 from '../assets/images/client_6.png'
import client_7 from '../assets/images/client_7.png'
import client_8 from '../assets/images/client_8.png'
import client_9 from '../assets/images/client_9.png'
import client_10 from '../assets/images/client_10.png'
import client_11 from '../assets/images/client_11.png'
import client_12 from '../assets/images/client_12.png'
import client_13 from '../assets/images/client_13.png'
import client_14 from '../assets/images/client_14.png'
import client_15 from '../assets/images/client_15.png'
import client_16 from '../assets/images/client_16.png'
import client_17 from '../assets/images/client_17.png'
import client_18 from '../assets/images/client_18.png'
import client_19 from '../assets/images/client_19.png'

const WorkWithGallery = () => {
    const paddingsScrollSnap = {
        left: {
            mobile: 2,
            tablet: 4, //768
            s: 4, //992
            m: 7, //1200
            l: 7, //1480
            xl: 9, //1780W
        },
    }

    return (
        <div className="work-with-gallery">
            <ScrollSnap
                type="mandatory"
                columnGap="25"
                columnGapDt="25"
                columnGapDtLarge="45"
                paddings={paddingsScrollSnap}
            >
                <div className="client-item">
                    <img src={client_1} alt="Riu hotels & resorts" />
                </div>
                <div className="client-item">
                    <img src={client_2} alt="Palladium hotel group" />
                </div>
                <div className="client-item">
                    <img src={client_3} alt="Valentin Perla Blanca" />
                </div>
                <div className="client-item">
                    <img src={client_4} alt="Pacific Destination" />
                </div>
                <div className="client-item">
                    <img src={client_5} alt="Valentin Cayo Cruz" />
                </div>
                <div className="client-item">
                    <img src={client_6} alt="Be Live Hotels Collection Resorts" />
                </div>
                <div className="client-item">
                    <img src={client_7} alt="Amresorts" />
                </div>
                <div className="client-item">
                    <img src={client_8} alt="Asistencia medica COVID-19 Universal assistance" />
                </div>
                <div className="client-item">
                    <img src={client_9} alt="Bahia Principe" />
                </div>
                <div className="client-item">
                    <img src={client_10} alt="Ocean by H10 Hotels" />
                </div>
                <div className="client-item">
                    <img src={client_11} alt="V Collection" />
                </div>
                <div className="client-item">
                    <img src={client_12} alt="Valentin El patriarca verdadero" />
                </div>
                <div className="client-item">
                    <img src={client_13} alt="Sandos Hotels & Resorts" />
                </div>
                <div className="client-item">
                    <img src={client_14} alt="Viva Wyndham" />
                </div>
                <div className="client-item">
                    <img src={client_15} alt="Copa Airlines" />
                </div>
                <div className="client-item">
                    <img src={client_16} alt="Special tours" />
                </div>
                <div className="client-item">
                    <img src={client_17} alt="Wamos circuitos" />
                </div>
                <div className="client-item">
                    <img src={client_18} alt="Verde Sicilia Tour Operator" />
                </div>
                <div className="client-item">
                    <img src={client_19} alt="DTS Tourism L.L.C" />
                </div>
            </ScrollSnap>
        </div>
    )
}

export default WorkWithGallery
