import { BsWhatsapp } from 'react-icons/bs';

const WhatsappInfo = ({data, className}) => {

    return (
        <div className={`whatsapp-info ${className}`}>
            <img src={data.image} alt="" />
            <div className="text">
                <h4 className="title">{data.nameLastName}</h4>
                <p className="position">{data.position}</p>
                <p className={data.online ? 'online' : 'offline'}>{data.online ? 'Online' : 'Offline'}</p>
            </div>
            <a
                href={`https://wa.me/54${data.phone}`}
                target="_blank"
                rel="noopener noreferrer"
                className='button'
            >
                <BsWhatsapp size="2em" />
            </a>
        </div>
    )
}

export default WhatsappInfo