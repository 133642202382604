import React from 'react'
import { useTranslation } from 'react-i18next'

//Comps
import ScrollSnap from './ScrollSnap'
import OurProductsCard from './OurProductsCard'

const DOMAIN = process.env.REACT_APP_DOMAIN

const ProductsGallery = () => {
    const { t } = useTranslation()

    const paddingsScrollSnap = {
        left: {
            mobile: 2,
            tablet: 4, //768
            s: 4, //992
            m: 7, //1200
            l: 7, //1480
            xl: 9, //1780W
        },
    }

    return (
        <div className="products-gallery">
            <ScrollSnap
                type="mandatory"
                columnGap="36"
                columnGapDt="36"
                columnGapDtLarge="36"
                paddings={paddingsScrollSnap}
            >
                <OurProductsCard
                    hoverColor="variante-uno"
                    category="incoming"
                    link={`${t('links.incoming.link', { domain: DOMAIN })}`}
                />

                <OurProductsCard
                    hoverColor="variante-dos"
                    category="corporate"
                    link={`${t('links.corporate.link', { domain: DOMAIN })}`}
                />

                <OurProductsCard
                    hoverColor="variante-tres"
                    category="sports"
                    link={`${t('links.sports.link', { domain: DOMAIN })}`}
                />

                <OurProductsCard
                    hoverColor="variante-cuatro"
                    category="partners"
                    link={`${t('links.partners.link', { domain: DOMAIN })}`}
                />
            </ScrollSnap>
        </div>
    )
}

export default ProductsGallery
