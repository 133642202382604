import Translate from '../i18n/Translate'
// //Hooks
// import useSetI18nProviderInformation from '../hooks/useSetI18nProviderInformation'
//Images
import not_found_landscape_1x from '../assets/images/404-landscape.jpg'
import not_found_landscape_2x from '../assets/images/404-landscape@2x.jpg'
import not_found_portrait_1x from '../assets/images/404-portrait.jpg'
import not_found_portrait_2x from '../assets/images/404-portrait@2x.jpg'

const NotFound = () => {
    // useSetI18nProviderInformation('not_found')

    return (
        <main className="not-found page">
            <picture>
                <source
                    media="(orientation: landscape)"
                    srcSet={`${not_found_landscape_1x} 1x, ${not_found_landscape_2x} 2x`}
                />
                <source
                    media="(orientation: portrait)"
                    srcSet={`${not_found_portrait_1x} 1x, ${not_found_portrait_2x} 2x`}
                />

                <img className="not-found__image" src={not_found_landscape_1x} alt="404-server-error" />
            </picture>
            <div className="not-found__content-top">
                <h1>
                    <Translate langkey="not_found.title" />
                </h1>
            </div>
            <div className="not-found__content-bottom">
                <h2>
                    <Translate langkey="not_found.subtitle" />
                </h2>
                <p>
                    <Translate langkey="not_found.copy" />
                </p>
            </div>
        </main>
    )
}

export default NotFound
