import {  useEffect, useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Translate from '../i18n/Translate'

import { I18nContext } from '../context/I18nProvider'
import api from '../api'

//SVGs
import WarningSvg from './svg/WarningSvg'


const ContactForm = () => {
    const { language } = useContext(I18nContext)
    const { t } = useTranslation()
    //Substraer todos los caracteres luego de los dos primeros en caso de que el language sea mayor a 2 EJ: es-ES
    let lang = language.length > 2 ? language.substr(0, 2) : language;
    
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm()

    const [txtAreaClick, setTxtAreaClick] = useState(false)
    const [sendedForm, setSendedForm] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState({
        loading: false,
        error: null,
        response: null,
    })


    useEffect(() => {
        if (isSubmitting.response) {
            setSendedForm(isSubmitting.response.status)
            //Si la respuesta es TRUE reseteo el formulario
            isSubmitting.response.status && reset()
        }
    }, [isSubmitting])

    //   Al momento de hacer submit
    const onSubmit = async (data) => {
        // creamos un estado de carga
        setIsSubmitting({ loading: true, error: null })

        // Espermos la respuesta del servidor
        try {
            const apiData = await api.contact.default({ ...data, language: lang })
            setIsSubmitting({ loading: false, response: apiData })
        } catch (error) {
            console.error(error)
            setIsSubmitting({ loading: false, error: error })
        }
    }

    // Registro al useForm hook de cada input, aca se pasan los parametros de validacion para cada campo, asi como los mensajes de error que luego son usados en los span.

    let nameRegistration = register('name', {
        required: `${t('form.requiredField')}`,
        pattern: {
            value: /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g, //acepta tildes y ñ, Ñ,
            message: `${t('form.wrongField')}`,
        },
    })

    let emailRegistration = register('email', {
        required: `${t('form.requiredField')}`,
        pattern: {
            value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            message: `${t('form.wrongField')}`,
        },
    })

    let issueRegistration = register('issue', {
        required: `${t('form.requiredField')}`,
    })

    let messageRegistration = register('message', {
        required: `${t('form.requiredField')}`,
        minLength: {
            value: 10,
            message: `${t('form.minChars', { num: 10 })}`,
        },
        maxLength: {
            value: 1000,
            message: `${t('form.maxChars', { num: 1000 })}`,
        },
    })

    const handleTextArea = () => {
        setTxtAreaClick(true)
    }

    return (
        <form
            className="contact-form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
        >
            <div className="input-group">
                <label htmlFor="name">
                    <Translate langkey="form.labels.name" />
                </label>

                <input
                    {...nameRegistration}
                    type="text"
                    id="name"
                    autoComplete="off"
                    aria-invalid={errors.name ? 'true' : 'false'}
                />
                {errors.name && (
                    <span className="error-msg" role="alert">
                        {errors.name.message}
                    </span>
                )}
                {errors.name && (
                    <span className="error-icon">
                        <WarningSvg />
                    </span>
                )}
            </div>

            <div className="input-group">
                <label htmlFor="email">
                    <Translate langkey="form.labels.email" />
                </label>
                <input
                    {...emailRegistration}
                    type="email"
                    id="email"
                    autoComplete="off"
                    aria-invalid={errors.email ? 'true' : 'false'}
                />
                {errors.email && (
                    <span className="error-msg" role="alert">
                        {errors.email.message}
                    </span>
                )}
                {errors.email && (
                    <span className="error-icon">
                        <WarningSvg />
                    </span>
                )}
            </div>

            <div className="input-group">
                <label htmlFor="issue">
                    <Translate langkey="form.labels.issue" />
                </label>
                <input
                    {...issueRegistration}
                    type="text"
                    id="issue"
                    autoComplete="off"
                    aria-invalid={errors.issue ? 'true' : 'false'}
                />
                {errors.issue && (
                    <span className="error-msg" role="alert">
                        {errors.issue.message}
                    </span>
                )}
                {errors.issue && (
                    <span className="error-icon">
                        <WarningSvg />
                    </span>
                )}
            </div>

            <div className="input-group">
                <label htmlFor="message">
                    <Translate langkey="form.labels.message" />
                </label>
                <textarea
                    {...messageRegistration}
                    id="message"
                    autoComplete="off"
                    aria-invalid={errors.message ? 'true' : 'false'}
                    className={`textarea ${txtAreaClick ? 'has-been-active' : ''}`}
                    onClick={handleTextArea}
                    onFocus={handleTextArea}
                />
                {errors.message && (
                    <span className="error-msg" role="alert">
                        {errors.message.message}
                    </span>
                )}
                {errors.message && (
                    <span className="error-icon">
                        <WarningSvg />
                    </span>
                )}
            </div>
            {isSubmitting.loading && (
                <span className="sended-form loading">
                    <Translate langkey="form.loadingMsg" />
                </span>
            )}
            {isSubmitting.error && (
                <span className="sended-form error">
                    <Translate langkey="form.errorMsg" />
                </span>
            )}
            {sendedForm && (
                <span className="sended-form">
                    <Translate langkey="form.sendedMsg" />
                </span>
            )}

            <button className="submit-btn btn" type="submit">
                <span>
                    <Translate langkey="form.send" />
                </span>
            </button>
        </form>
    )
}

export default ContactForm
